import { Flex, Heading, Link, List, ListItem, Text } from '@chakra-ui/react';
import React from 'react';
import { theme } from '../../@chakra-ui/gatsby-plugin/theme';

export const PrivacyPolicyCard: React.FC = () => {
    return (
        <Flex
            direction="column"
            alignSelf="stretch"
            mx={['2', '0']}
            px="6"
            py="6"
            gap="4"
            border="1px"
            borderRadius="xl"
            borderColor={theme.brandColors.border}
            backgroundColor={theme.brandColors.background}
            shadow="0px 6px 15px rgba(26, 27, 56, 0.25)"
            transition="max-height 300ms ease"
            role="region"
        >
            <Heading as="h2" size="lg">
                Polityka Prywatności
            </Heading>

            <Heading as="h3" size="md">
                Administrator danych
            </Heading>
            <Text>
                Administratorem Twoich danych osobowych jest Przedsiębiorstwo Produkcyjne SILMET z siedzibą w
                Siemianowicach Śląskich przy ul. Składowej 9 <br />
                NIP: 9542779326.
            </Text>

            <Heading as="h3" size="md">
                Kontakt w sprawie danych osobowych
            </Heading>
            <Text>
                Mailowo:&nbsp;
                <Link href="mailto:biuro@silmet.pl">biuro@silmet.pl</Link>
            </Text>

            <Heading as="h3" size="md">
                Cel przetwarzania danych
            </Heading>
            <Text paddingLeft="2">
                <List>
                    <ListItem>● nawiązywanie kontaktów biznesowych i sprzedażowych</ListItem>
                    <ListItem>● świadczenie usług i utrzymywanie kontaktu</ListItem>
                    <ListItem>● marketing usług firmy Silmet</ListItem>
                </List>
            </Text>

            <Heading as="h3" size="md">
                Źródła danych
            </Heading>
            <Text paddingLeft="2">
                <List>
                    <ListItem>
                        ● dostarczane przez użytkowników przede wszystkim, ale nie wyłącznie, poprzez bezpośredni
                        kontakt formularze, e-maile
                    </ListItem>
                    <ListItem>
                        ● ze źródeł publicznych, takich jak strony internetowe, media społecznościowe, platformy
                        sieciowe
                    </ListItem>
                    <ListItem>● automatycznie (np. odwiedzając tę stronę internetową)</ListItem>
                    <ListItem>● od stron trzecich, głównie, ale nie wyłącznie, poprzez rekomendacje</ListItem>
                </List>
            </Text>

            <Heading as="h3" size="md">
                Odbiorcy danych osobowych
            </Heading>
            <Text>
                Twoje dane osobowe mogą zostać przekazane niektórym usługodawcom, z którymi współpracuje firma Silmet -
                są to przede wszystkim, ale nie wyłącznie, dostawcy zajmujący się marketingiem.
            </Text>

            <Heading as="h3" size="md">
                Okres przetwarzania danych
            </Heading>
            <Text>
                Twoje dane osobowe będą przetwarzane do czasu skutecznego sprzeciwu wobec tego przetwarzania, albo do
                czasu rozwiązania umów, albo tak długo, jak przepisy prawa tego wymagać.
            </Text>

            <Heading as="h3" size="md">
                Twoje prawa dotyczące danych osobowych:
            </Heading>
            <Text paddingLeft="2">
                <List>
                    <ListItem>● dostęp do Twoich danych w celu ich poprawienia</ListItem>
                    <ListItem>● wycofanie zgody na przetwarzanie danych osobowych</ListItem>
                    <ListItem>● wniesienie sprzeciwu wobec przetwarzania Twoich danych osobowych</ListItem>
                    <ListItem>
                        ● sprzeciw wobec przetwarzaniu Twoich danych osobowych w celu marketingu bezpośredniego
                    </ListItem>
                    <ListItem>● uzyskanie przechowywanych danych osobowych</ListItem>
                </List>
            </Text>
            <Text>
                Aby skorzystać z któregokolwiek z powyższych praw, skontaktuj się z nami pod adresem:&nbsp;
                <Link href="mailto:biuro@silmet.pl">biuro@silmet.pl</Link>.
            </Text>

            <Heading as="h3" size="md">
                Narzędzia wykorzystywane stronie internetowej
            </Heading>
            <Text>
                W celach marketingowych korzystamy z narzędzi stron trzecich wymienionych poniżej w celu analizy
                zachowań użytkowników zachowanie i dostosowywanie reklam. Narzędzia te mogą wykorzystywać pliki cookie
                lub wysyłać zgromadzonych danych na serwery zewnętrzne.
            </Text>
            <Text paddingLeft="2">
                <List>
                    <ListItem>● Google Analytics</ListItem>
                    <ListItem>● Google Tag Manager</ListItem>
                    <ListItem>● Google Ads System</ListItem>
                </List>
            </Text>

            <Heading as="h3" size="md">
                Konieczność przetwarzania danych osobowych
            </Heading>
            <Text>
                Zgoda na przetwarzanie danych osobowych jest dobrowolna, jednak odmowa moze skutkować utrudnionym
                nawiązaniem kontaktu w celach biznesowych.
            </Text>
        </Flex>
    );
};
